.wrapperWithoutBtn {
    display: flex;
    gap: 8px;
    padding-left: 12px;
    align-items: center;
    min-width: 266px;
    height: 36px;
    justify-content: flex-start;
    position: relative;
}

.wrapperWithoutBtn input {
    border: none;
    flex-grow: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    z-index: 1;
    max-height: 14px;
    min-height: 20px;
    max-width: 128px;
    margin: 0 0 0 12px;
    padding-bottom: 1px;
}

.wrapperWithoutBtn input:hover,
.wrapperWithoutBtn input:focus-visible {
    outline: none;
}
.wrapperWithoutBtn input::placeholder {
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
}

.label {
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    opacity: 0.3;
}

.borderWithoutBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid var(--grey-40);
    border-radius: 24px;
    height: 36px;
}

.clearBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 4px;
    height: 36px;
    z-index: 1;
}
.wrapperWithoutBtn .clearBtn {
    position: absolute;
    right: 16px;
}
