.title {
    display: flex;
    align-items: center;
    gap: 28px;
}
.title > div:first-child {
    cursor: pointer;
}

.wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto auto auto;
    flex-grow: 1;
    padding-bottom: 20px;
}

.scanInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.scanInfo:last-child {
    margin-bottom: 40px;
}

.pictureWrapper {
    width: 170px;
    height: 177px;
}
.pictureWrapper > div {
    width: 100%;
    height: 100%;
}
