.wrapper {
    flex-grow: 1;
}

.pictures {
    display: flex;
}

.textWrapper {
    display: flex;
}

.left,
.right {
    width: 50%;
}

.buttons,
.oneButton {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;

    margin-bottom: 32px;
    border-bottom: 1px solid var(--grey-5);
    padding-bottom: 20px;
}
.oneButton {
    border-bottom: none;
}
.buttons button,
.oneButton button {
    width: 200px;
}

.pictureSign {
    margin: 8px 0 20px;
    font-weight: 600;
}
.pictureSign span {
    font-weight: 400;
}

.blockTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 8px;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 32px;
}
.info div {
    line-height: 130%;
}
.onPicture {
    position: absolute;
    bottom: 12px;
    right: 12px;
}

.infoCard {
    padding: 12px 0;
}
.infoCardTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 4px;
}
