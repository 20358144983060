.list {
    position: relative;
    width: 100%;
    border-spacing: 0;
}

.list thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background-color: var(--white-100);
    white-space: nowrap;
    font-weight: 600;
}

.list th,
.list td {
    padding: 16px 10px 18px;
    text-align: start;
    vertical-align: top;
    line-height: 130%;
}

.list th:last-child {
    text-align: center;
}

.list tbody tr {
    transition: background-color .2s ease-in-out;
}
.list tbody tr:hover {
    background: rgba(0, 0, 0, 0.02);
}

.clickable {
    cursor: pointer;
}

.notFoundWrapper {
    height: 50vh;
    width: 360px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: var(--grey-40);
    text-align: center;
}

.notFoundText {
    font-weight: 400;
    font-size: 14px;
    line-height: 140% !important;
}

.notFoundTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    color: var(--black-100);
}
