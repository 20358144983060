.default,
.positive,
.negative,
.big,
.selected,
.readyToSelect,
.disabled,
.producing {
    background-color: var(--grey-5);
    padding: 3px 10px 4px;
    border-radius: 14px;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    width: min-content;
    white-space: nowrap;
    display: block;
    opacity: 1;
}

.text {
    line-height: 130%;
}

.positive {
    background-color: var(--green-80);
    font-weight: 400;
}

.negative {
    background-color: var(--orange-80);
    font-weight: 400;
}

.producing {
    background-color: var(--blue-100);
    font-weight: 400;
}

.big,
.selected,
.readyToSelect,
.disabled {
    font-weight: 400;
    line-height: 100% !important;
    padding: 10px 14px 12px;
    border-radius: 40px;
    height: 34px;
    border: 1px solid transparent;
}
.big .text,
.selected .text,
.readyToSelect .text,
.disabled .text {
    line-height: 100% !important;
}

.selected {
    background-color: var(--green-100);
    border: 1px solid var(--black-100);
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}
.readyToSelect {
    border: 1px solid var(--black-100);
    background-color: inherit;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}
.readyToSelect:hover {
    background-color: var(--grey-5);
}

.hidden {
    display: none;
}

.disabled {
    cursor: not-allowed !important;
}

.withIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}
.withIcon svg {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hoverable {
    cursor: pointer;
}
