.image {
    width: 72px;
    height: 100px;
}
.imageFit {
    width: 100%;
    height: 100%;
}
.image img,
.imageFit img {
    object-fit: cover;
    border-radius: 6px;
    width: 100%;
    height: 100%;
}
.noImage {
    width: 72px;
    height: 100px;
}
.noImageFit {
    width: 100%;
    height: 100%;
}
.noImage img,
.noImageFit img,
.noImageInner svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.noImageInner {
    height: inherit;
    background-color: #F3F3F3;
    border-radius: 6px;
}

.imageHidden {
    opacity: 0;
}

.skeleton {
    width: 100%;
    height: 100%;
    animation: shine 1.6s infinite linear;
    background-image: linear-gradient(90deg, var(--grey-5) 0px, var(--white-100) 40px, var(--grey-5) 80px);
    background-size: 380px;
    border-radius: 6px;
}
.skeletonHidden {
    display: none;
}

@keyframes shine {
    0% {
        background-position: 0;
    }

    100% {
        background-position: 380px;
    }
}
