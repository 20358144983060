.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 32px;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 420px;
    height: 48px;
}
