.wrapper {
    flex-grow: 1;
}

.pictures {
    display: flex;
}

.textWrapper {
    display: flex;
    margin-top: 20px;
    border-top: 1px solid var(--grey-5);
    padding-top: 32px;
}

.left,
.right {
    width: 50%;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    height: 48px;
}
.buttons button {
    width: 200px;
}

.pictureSign {
    margin: 8px 0 20px;
    font-weight: 600;
}
.pictureSign span {
    font-weight: 400;
}

.blockTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 8px;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 32px;
}
.info div {
    line-height: 130%;
}
.infoCard {
    padding: 12px 0;
    line-height: 130%;
}
.infoCardTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 4px;
}
.infoCardTags {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}
.infoCard :last-child {
    line-height: 130%;
}
