.wrapper,
.wrapperActive,
.wrapperNotEditable,
.wrapperInvalid {
    padding: 12px;
    background-color: var(--white-100);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
    transition: background-color .2s ease-in-out,
                border .2s ease-in-out;
    position: relative;
}
.wrapper *,
.wrapperActive * {
    cursor: pointer;
}
.wrapper:hover {
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.wrapperActive {
    border-color: var(--black-100);
}
.wrapperInvalid {
    border-color: var(--orange-100);
}
.wrapperNotEditable,
.wrapperNotEditable * {
    cursor: default;
    pointer-events: none;
}

.title {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
}
.title span {
    font-weight: 400;
}

.modeLabel,
.modeLabelHidden {
    position: absolute;
    top: 12px;
    right: 12px;
    font-weight: 600;
    line-height: 130%;
    opacity: 1;
    transition: opacity .2s ease-in-out;
}
.modeLabelHidden {
    opacity: 0;
}
.tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.tags span,
.input:empty:before {
    content: attr(data-placeholder);
    color: var(--black-100);
    opacity: .4;
}
.input,
.input * {
    line-height: 130%;
    display: inline-block;
}
.hiddenInput {
    display: none;
}

.readMore {
    font-weight: bold;
    text-decoration: underline;
}
