.text {
    color: var(--black-100);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 32px;
    padding-top: 8px;
}

.controls {
    width: 100%;
}
