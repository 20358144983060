.searchBlock {
    display: flex;
    gap: 12px;
}

.topline {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.result {
    position: relative;
    padding: 8px 0 2px;
    width: 100%;
    z-index: 1;
}

.result tr {
    vertical-align: baseline;
}
.result td {
    padding: 8px 16px 22px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--black-100);
}
.result td * {
    line-height: 130%;
}

.result .resultTitle {
    font-weight: 600;
    padding-left: 18px;
}
.result td:not(.resultTitle) {
    padding-right: 24px;
}
.result .bigTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
}
.result .footer {
    font-size: 9px;
    line-height: 130%;
}

.result td:nth-child(odd) {
    width: 14%;
}
.result td:nth-child(even) {
    width: 36%;
}
.result .rightTitle {
    padding-left: 26px;
}

.tableBackLeft,
.tableBackRight {
    background: rgba(0, 0, 0, 0.03);
    border-radius: 16px;
    position: absolute;
    height: 100%;
    width: 49.3%;
    top: 0;
    z-index: -1;
}
.tableBackLeft {
    left: 0;
}
.tableBackRight {
    right: 0;
}

.emptyWrapper {
    padding-top: 10%;
    max-width: 392px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.emptyWrapper .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
    color: var(--black-100);
    margin-bottom: 16px;
}
.emptyWrapper .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: var(--grey-40);
}
.emptyWrapper .icon {
    margin-bottom: 8px;
}

.wrapper {
    padding-bottom: 48px;
}
