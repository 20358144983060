.wrapper,
.wrapperWithTitle {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 36px;
}
.wrapperWithTitle {
    margin-bottom: 32px;
    align-items: start;
}

.switcher {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
.border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid var(--grey-40);
    border-radius: 24px;
    height: 36px;
}

.tab {
    width: 120px;
    height: 36px;
}

.left {
    display: flex;
    gap: 20px;
    flex-grow: 1;
}
.right,
.mobileRight {
    height: 36px;
}

.mobileRight {
    display: none;
}

.mobileMenu {
    width: 100%;
}

.pageTitle {
    color: var(--black-100);
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
    margin: 0;
}

.buttonsWrapper {
    display: flex;
    gap: 12px;
    padding-top: 4px;
}

@media screen and (max-width: 1024px) {
    .wrapper {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: fit-content;
        gap: 16px;
    }

    .left {
        flex-direction: column;
        width: 100%;
    }

    .switcher {
        width: 100%;
    }

    .mobileRight {
        display: block;
        width: fit-content;
    }

    .right {
        display: none;
    }

    .mobileMenu {
        display: flex;
        justify-content: space-between;
    }
}
