.wrapper {
    padding: 5px 8px;
}

.option,
.regular {
    color: var(--black-100);
    font-size: 12px;
    font-weight: 600;
    line-height: 130%;
}
.regular {
    font-weight: 400;
}
