.wrapper {
    padding: 8px 8px 24px 8px;
    border-radius: 16px;
    background-color: #F7F7F7;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px 0 10px;
    margin-bottom: 10px;
}

.title {
    font-size: 22px;
    line-height: 120%;
    font-weight: bold;
    margin: 0;
}

.description {
    padding: 0 10px 10px 10px;
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 12px;
}

.graph {
    margin-bottom: 12px;
}

/* Badge */
.badgeWrapper,
.badgeWrapperBad {
    padding: 4px 7px 5px 7px;
    border-radius: 5px;
    display: flex;
    align-items: end;
    gap: 4px;
    font-size: 12px;
    line-height: 120%;
    font-weight: 600;
    background-color: var(--green-100);
}
.badgeWrapperBad {
    background-color: var(--orange-80);
    padding-top: 5px;
}

.badgeWrapper svg {
    margin-bottom: 0.5px;
}

.badgeRiseDownIcon {
    margin-bottom: 1px;
    transform: rotate(90deg);
}
