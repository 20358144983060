.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    min-height: 36px;
    margin-bottom: 32px;
}

.right,
.rightWide,
.left {
    display: flex;
    align-items: center;
    justify-content: center;
}
.right {
    gap: 12px;
}
.rightWide {
    gap: 42px;
}
.static {
    display: flex;
}

.name {
    color: var(--grey-40);
    line-height: 130%;
}
.label {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: var(--black-100);
    opacity: 1;
    transition: opacity .25s ease-in-out;
}
.label:hover {
    opacity: 0.6;
}

.button {
    width: 100px;
}

.center {
    display: flex;
    gap: 40px;
}

.navLink,
.navLinkDisabled {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: var(--black-100);
}
.navLink:global(.active) {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    text-decoration-line: underline;
    color: var(--black-100);
}
.navLinkDisabled {
    opacity: 0.4;
    cursor: not-allowed;
}
.navLinkForbidden {
    display: none;
}
