.wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;
    padding: 12px;
    border-radius: 16px;
    border: 1px solid var(--grey-20);
    transition: background-color .2s ease-in-out,
                border .2s ease-in-out;
    position: relative;
}
.wrapper,
.wrapper * {
    cursor: pointer;
}
.wrapper:hover {
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.wrapper:not(.wrapperActive):hover .input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px rgba(0, 0, 0, 0.02) !important;
}

.wrapperActive {
    border: 1px solid var(--black-100);
    background-color: var(--white-100) !important;
}

.title {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
}
.wrapperActive .title,
.wrapperFilled .title {
    font-weight: 400;
    font-size: 12px;
    color: var(--grey-40);
}

.input::placeholder {
    color: var(--grey-40);
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
}
.input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px var(--white-100) !important;
    transition: box-shadow .2s ease-in-out, background-color 10s ease-in-out;
}

.shortInput {
    width: calc(100% - 54px);
}
.wrapperActive input,
.wrapperFilled input {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
}

.modeLabel {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-weight: 600;
    opacity: 1;
    transition: opacity .2s ease-in-out;
}

/******************************************/
.withSuggests {
    position: relative;
}

.suggests {
    background: var(--white-100);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    padding: 8px 0;
    margin-top: 4px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.suggest {
    padding: 12px;
    font-weight: 600;
    transition: background-color .2s ease-in-out;
    cursor: pointer;
}
.suggest:hover,
.suggest:focus-within,
.suggest:focus {
    background-color: var(--grey-5);
}
