.wrapper,
.loaderWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 32px;
}
.loaderWrapper {
    height: 272px;
}

.options {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
}
