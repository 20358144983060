.wrapper {
    height: 100%;
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: var(--black-100);
    text-align: center;
}

.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: var(--black-100);
}

.description {
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

.button {
    width: 100%;
    margin-top: 24px;
}
