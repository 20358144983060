.wrapper,
.wrapperMain {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    position: relative;
    width: 110px;
    height: 110px;
}
.wrapperMain {
    width: 380px;
    height: 380px;
    min-height: 380px;
}
.active {
    border: 1px solid rgba(0, 0, 0, 0.8);
}

.wrapper img,
.wrapperMain img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 16px;
}

.menu {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: var(--white-100);
    position: absolute;
    top: 12px;
    right: 12px;
    transition: background-color .2s ease-in-out;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.menu:hover {
    background-color: var(--grey-1);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    z-index: 200;
}

.dropdown {
    background-color: var(--white-100);
    width: 184px;
    border-radius: 12px;
    padding: 8px 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
    position: absolute;
    right: initial;
    left: 72px;
    top: 44px;
    z-index: 201;
}
.wrapperMain .dropdown {
    left: initial;
    right: 12px;
}

.dropdownItem {
    display: block;
    padding: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    transition: background-color .2s ease-in-out;
    cursor: pointer;
}
.dropdownItem:hover {
    background-color: var(--grey-5);
}

.noPhoto {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--black-100);
    opacity: .4;
    padding: 0 8px;
    text-align: center;
}

.imageHidden {
    opacity: 0;
}

.clickable {
    cursor: pointer;
}

.skeleton {
    width: 100%;
    height: 100%;
    animation: shine 1.6s infinite linear;
    background-image: linear-gradient(90deg, var(--grey-5) 0px, var(--white-100) 40px, var(--grey-5) 80px);
    background-size: 380px;
    border-radius: 16px;
}
.skeletonHidden {
    display: none;
}

.addImageButton {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.addImageButton button {
    width: fit-content;
    height: fit-content;
}

.addImageLabel {
    position: relative;
}

.addImageSelector {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
}
.addImageSelector::file-selector-button {
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
}

.tag {
    position: absolute;
    top: 13px;
    left: 10px;
}

.instructions {
    max-width: 250px;
    color: var(--grey-40);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
}

@keyframes shine {
    0% {
        background-position: 0;
    }

    100% {
        background-position: 380px;
    }
}
