.photos {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.scansTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.scansCount {
    font-weight: 400;
}
.scansWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 14px;
}
.scanInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.scansSeeAllBtn {
    font-size: 12px;
    line-height: 130%;
    text-decoration: underline;
    cursor: pointer;
    opacity: 1;
    transition: opacity .2s ease-in-out;
}
.scansSeeAllBtn:hover {
    opacity: 0.7;
}
.photos,
.info {
    width: 50%;
}
.info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.buttons {
    min-height: 40px;
}
.buttonsVisible {
    min-height: initial;
    height: 88px;
    display: flex;
    gap: 20px;
    margin-top: 24px;
    padding-bottom: 40px;
}
.buttons > button {
    width: 50%;
}

.action {
    transition: background-color .2s ease-in-out;
    cursor: pointer;
    border-radius: 20px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.action svg {
    width: 22px;
    height: 22px;
}
.action:hover {
    background-color: var(--grey-5);
}

.pictureSign {
    margin: -4px 0 8px;
    font-weight: 600;
    line-height: 130%;
}
.pictureSign span {
    font-weight: 400;
    line-height: 130%;
}

.error {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    margin: 20px 0 0;
    opacity: 1;
    padding: 12px;
    background-color: var(--white-100);
    border: 1px solid var(--orange-100);
    border-radius: 16px;
}
.errorHidden {
    opacity: 0;
}
.errorText {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--grey-40);
}

.underPictureSign {
    margin-top: -4px;
}

.titleWithIcon {
    display: flex;
    align-items: center;
    gap: 4px;
}
.titleWithIcon svg {
    cursor: pointer;
}
