.wrapper {
    padding-bottom: 48px;
}

.topline {
    display: flex;
    justify-content: space-between;
    margin-bottom: 43px;
}

.searchBlock {
    display: flex;
    gap: 12px;
}

.result {
    display: flex;
    gap: 20px;
}

.result tr {
    vertical-align: baseline;
}

.left,
.right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.block {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px 8px 24px;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.03);
}

.blockTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    padding: 8px 10px 10px;
}

.blockTitleWrapper {
    display: flex;
    justify-content: space-between;
}

.emptyWrapper {
    padding-top: 10%;
    max-width: 392px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.emptyWrapper .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
    color: var(--black-100);
    margin-bottom: 16px;
}
.emptyWrapper .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: var(--grey-40);
}
.emptyWrapper .icon {
    margin-bottom: 16px;
}

.footer {
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.footer button {
    width: fit-content;
}

.legal {
    margin-top: -2px;
    display: flex;
    justify-content: center;
}

.loader {
    height: 450px;
    display: flex;
}
