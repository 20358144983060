@font-face {
  font-family: 'Golos';
  src: url('assets/fonts/Golos-Text_Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Golos';
  src: url('assets/fonts/Golos-Text_DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Golos';
  src: url('assets/fonts/Golos-Text_Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

* {
  box-sizing: border-box;
  line-height: 100%;
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Golos';
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: var(--black-100);
  background-color: var(--white-100);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
  font-family: 'Golos';
  border: none;
}

button {
  cursor: pointer;
}
button:focus-visible,
input:focus-visible,
textarea:focus-visible,
div:focus-visible {
  outline: none;
}

a {
  display: inline-block;
  text-decoration: none;
  text-align: center;
}

input,
textarea {
  padding: 0;
  background: none;
  resize: none;
}

.overflowed {
  overflow: hidden;
}

.contentLoaderUnder {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  width: 100px;
  height: 100px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  top: calc(50% - 50px);
  left: calc(50% - 55px);

  position: fixed;
  z-index: 998;
}
.pageloaderWrapper,
.contentLoaderWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageloader,
.pageloader:after {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.pageloader {
  margin: -3px auto;
  position: relative;
  border-top: 2.5px solid rgba(255, 255, 255, 0.2);
  border-right: 2.5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2.5px solid rgba(255, 255, 255, 0.2);
  border-left: 2.5px solid var(--black-100);
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;
}

.contentLoader,
.contentLoader:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.contentLoader {
  margin: -3px auto;
  position: relative;
  border-top: 2.5px solid var(--white-100);
  border-right: 2.5px solid var(--white-100);
  border-bottom: 2.5px solid var(--white-100);
  border-left: 2.5px solid rgba(0, 0, 0, 0.5);
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;
}

.selectedInfo {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.selectedInfoCount {
  font-weight: 600;
  padding: 0 12px;
}
.selectedInfoText {
  color: var(--grey-40);
  margin-right: 12px;
}
.selectedInfoButtons {
  display: flex;
  gap: 12px;
}
.selectedInfoButton {
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

:root {
  --black-100: #000000;
  --white-100: #FFFFFF;
  --grey-1: #F2F2F2;
  --grey-5: rgba(0, 0, 0, 0.05);
  --grey-20: rgba(0, 0, 0, 0.2);
  --grey-40: rgba(0, 0, 0, 0.4);
  --grey-light: #A0A3B1;
  --green-80: #DAFF8B;
  --green-100: #CFFF69;
  --green-120: #B6E551;
  --orange-80: #FF834E;
  --orange-100: #E25C23;
  --icon-fill: var(--white-100);
  --blue-100: #BCE1EA;
}
