.cellName,
.cellValue,
.cellCollapsed {
    line-height: 130%;
    font-size: 12px;
    font-weight: 400;
    padding: 8px 10px 22px;
}
.cellCollapsed {
    padding: 0 10px;
}

.cellName *,
.cellValue *,
.cellCollapsed * {
    line-height: 130%;
}

.cellName {
    font-weight: bold;
    width: 165px;
}

.cellValue span,
.cellCollapsed span {
    white-space: pre-line;
}
