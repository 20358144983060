.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    width: 320px;
}

.button {
    width: 320px;
    height: 48px;
    margin: 32px 0;
}

.help {
    width: 320px;
    display: flex;
    justify-content: space-between;
}
.help a {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-decoration: underline;
    color: var(--black-100);
    opacity: 1;
    transition: opacity .25s ease-in-out;
}
.help a:hover {
    opacity: 0.6;
}

.error {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 12px;
    border: 1px solid var(--grey-5);
    border-radius: 16px;
    width: 320px;
    margin-top: 20px;
}
.errorText {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--grey-40);
}
