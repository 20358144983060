.wrapper {
    padding: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    flex-grow: 1;
    padding: 8px 10px 10px;
    color: var(--black-100);
    font-family: Golos;
    font-size: 16px;
    font-weight: 600;
    line-height: 130%;
}

.badgeWrapper {
    padding: 5px 16px 5px 8px;
}
.badgeNegative,
.badgePositive {
    padding: 5px 8px 6px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    background: var(--orange-80);
}
.badgePositive {
    background: var(--green-100);
}
.badgeText {
    color: var(--black-100);
    font-family: Golos;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 90%;
    letter-spacing: -0.475px;
    text-transform: uppercase;
}

.icon {
    display: flex;
}
.icon svg {
    width: 14px;
    height: 12px;
}
