.wrapper {
    padding: 0 10px;
}

.cards {}

.slideWrapper {}

.slide {
    display: flex;
    flex-direction: column;
}

.picture {
    margin-bottom: 10px;
    height: 140px;
    cursor: default;
}

.score {
    margin-bottom: 6px;
}

.title {
    color: var(--black-100);
    font-size: 14px;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 2px;
}

.text {
    color: var(--black-100);
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
}

.scoreInfo {
    display: flex;
    justify-content: space-between;
}

.infoIcon {
    cursor: pointer;
}

body .infoTooltip {
    --rt-opacity: 1;
    border-radius: 12px;
    background-color: var(--white-100);
    color: var(--black-100);
    width: min-content;
    z-index: 10000000;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
}

/* Tooltip inner styles */
.tooltipContentWrapper {
    padding: 4px 0;
    margin: 0 -4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.tooltipContentTexts {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
}

.tooltipContentTitle {
    font-size: 14px;
    font-weight: 600;
}
