.wrapperPositive,
.wrapperNegative,
.wrapperUndefined {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    background: var(--orange-80);
    border-radius: 8.5px;
    padding: 9px 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 80%;
}
.wrapperPositive {
    background: var(--green-100);
}
.wrapperUndefined {
    background-color: var(--grey-20);
}

.wrapperMini {
    padding: 3px 6px 3px 5px;
    gap: 2px;
    justify-content: initial;
    border-radius: 6px;
    font-size: 11px;
    font-weight: 600;
    line-height: 90%;
    width: max-content;
}
.wrapperMini svg {
    width: 10px;
    height: 9px;
}

.wrapperMicro {
    padding: 4px 8px;
    gap: 4px;
    justify-content: initial;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    line-height: 90%;
    width: max-content;
}
.wrapperMicro svg {
    width: 14px;
    height: 12px;
}

.icon {
    display: flex;
}
