.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 160px;
    height: fit-content;
}

.toggle {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 3px 6px 4px 10px;
    border-radius: 14px;
    border: 1px solid var(--grey-20);
    background: var(--white-100);
    width: auto;
    cursor: pointer;
}

.iconOpened {
    transform: rotate(180deg);
}

.current {
    flex-grow: 1;
}

.content {
    position: absolute;
    top: 28px;
    width: 100%;
    border-radius: 12px;
    background: var(--white-100);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
    padding: 8px 0;
}

.checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    user-select: none;
    transition: background-color .2s ease-in-out;
}

.checkbox .text {
    flex-grow: 1;
}
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    height: 16px;
    width: 16px;
}

.checkbox:hover {
    background-color: var(--grey-5);
}

.checkbox input:checked ~ .checkmark:after {
    border-color: var(--black-100);
}

.checkmark:after {
    content: "";
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    width: 4px;
    height: 8px;
    border: solid transparent;
    border-width: 0 2px 2px 0;
    margin: 1px 0 0 4px;
    transform: rotate(45deg);
}
