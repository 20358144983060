.infoCard {
    padding: 12px 0;
    line-height: 130%;
}
.infoCardTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 4px;
}
.infoCardTags {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}
.infoCard :last-child {
    line-height: 130%;
}
.noField {
    color: var(--grey-light);
}
