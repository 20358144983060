.wrapper {
    margin-bottom: 20px;
    display: flex;
    gap: 12px;
    width: max-content;
}

.button,
.buttonActive {
    padding: 10px 14px 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    border-radius: 40px;
    transition: background-color .2s ease-in-out,
                color .2s ease-in-out,
                opacity .2s ease-in-out,
                border .2s ease-in-out;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    position: relative;

    background-color: inherit;
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: var(--black-100);
}

.buttonActive {
    background-color: var(--black-100);
    border: 1px solid var(--black-100);
    color: var(--white-100);
}

.button:hover {
    background-color: var(--grey-5);
}

.buttonActive:hover {
    opacity: 0.8;
}
