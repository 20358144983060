.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    user-select: none;
    z-index: 999;
}

.wrapper,
.short {
    position: fixed;
    top: 40px;
    bottom: 40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--white-100);
    z-index: 999;
    border-radius: 16px;
    width: 820px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.short {
    width: 320px;
    top: 50%;
    bottom: initial;
    left: 50%;
    right: initial;
    transform: translate(-50%, -50%);
}

.header,
.headerShort {
    position: sticky;
    top: 0;
    z-index: 101;
    background-color: var(--white-100);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    margin: 0 20px 8px;
    padding: 12px 0;
}
.headerShort {
    border-bottom: initial;
    margin: initial;
    padding: 12px 12px 8px 12px;
}

.titleWrapperShort {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}
.title,
.titleShort {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
}
.title {
    padding: 5px 0;
}
.titleShort,
.titleDescription {
    text-align: center;
}
.titleDescription {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
}

.actions {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
}
.actionsWide {
    justify-content: end;
    width: 100%;
}
.action {
    transition: background-color .2s ease-in-out;
    cursor: pointer;
    border-radius: 20px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.actionsWide .action {
    width: 24px;
    height: 24px;
}
.action svg {
    width: 22px;
    height: 22px;
}
.actionsWide .action svg {
    width: 16px;
    height: 16px;
}
.action:hover {
    background-color: var(--grey-5);
}

.content {
    flex-grow: 1;
    display: flex;
    gap: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px 20px;
}
