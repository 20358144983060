.wrapper {
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link {
    color: var(--black-100);
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
    text-decoration-line: underline;
}
