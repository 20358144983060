.wrapper {
    padding: 32px 0 40px;
    border-top: 1px solid var(--grey-5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    gap: 8px;
}

.arrow,
.arrowRotate,
.arrowDisable,
.arrowDisableRotate {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 1;
    transition: opacity .25s ease-in-out;
}
.arrowRotate,
.arrowDisableRotate {
    transform: rotate(180deg);
}
.arrow:hover,
.arrowRotate:hover {
    opacity: 0.6;
}
.arrowDisable,
.arrowDisableRotate {
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;
}

/* Page */
.page,
.notClickablePage {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    border-radius: 20px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
    user-select: none;
}
.pageActive {
    background-color: var(--black-100);
    color: var(--white-100);
    cursor: default;
    user-select: none;
}
.notClickablePage {
    cursor: default;
    pointer-events: none;
}

.page:not(.pageActive):hover {
    background-color: var(--grey-5);
}
