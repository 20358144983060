.icon {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.title,
.subtitle,
.counter,
.description {
    color: var(--black-100);
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.32px;
    margin-bottom: 8px;
}
.subtitle {
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 16px;
    white-space: pre-wrap;
}
.counter {}
.description {
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 32px;
}

.controls {
    width: 100%;
}