/* SIZES */
/* default = mini */
.default,
.micro,
.tabSize {
    padding: 15px 24px 17px;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    border-radius: 24px;
    transition: background-color .2s ease-in-out,
                color .2s ease-in-out,
                opacity .2s ease-in-out,
                border .2s ease-in-out;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    position: relative;
}
.micro {
    font-size: 14px;
    padding: 10px 20px 12px;
}
.tabSize {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    padding: 10px 14px 12px;
    height: auto;
}
.icon {
    padding: 8px 22px;
    max-height: 100%;
    display: flex;
    align-items: center;
}

/* THEMES */
.filled,
.filledDisabled {
    background-color: var(--black-100);
    opacity: 1;
    color: var(--white-100);
    border: 1px solid transparent;
}
.filled.tabSize {
    border: initial;
}
.filledDisabled {
    opacity: 0.2;
    cursor: not-allowed;
}
.stroked {
    background-color: inherit;
    border: 1px solid var(--black-100);
    color: var(--black-100);
}
.tabTheme {
    color: var(--black-100);
    background-color: inherit;
}

/* STATES */
.filled:hover {
    opacity: 0.8;
}
.filled:active {
    background-color: inherit;
    border: 1px solid var(--black-100);
    color: var(--black-100);
    --icon-fill: var(--black-100);
}
.stroked:hover,
.tabTheme:hover {
    background-color: var(--grey-5);
}
.stroked:active,
.tabTheme:active {
    background-color: var(--black-100);
    opacity: 1;
    color: var(--white-100);
}

/* EXTRA */
.top {
    position: absolute;
    top: 6px;
    font-size: 9px;
    line-height: 100%;
    font-weight: 500;
    color: var(--white-100);
    background-color: var(--black-100);
    border-radius: 7px;
    padding: 2px 5px 3px;
    margin-left: 4px;
}
.filled .top {
    color: var(--black-100);
    background-color: var(--white-100);
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
.loader {
    margin: -3px auto;
    position: relative;
    border-top: 2.5px solid rgba(255, 255, 255, 0.2);
    border-right: 2.5px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2.5px solid rgba(255, 255, 255, 0.2);
    border-left: 2.5px solid #ffffff;
    transform: translateZ(0);
    animation: loading 1.1s infinite linear;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
