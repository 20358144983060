.wrapper,
.wrapperChecked {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: var(--white-100);
    transition: background-color .2s ease-in-out;
    cursor: pointer;

    color: var(--black-100);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}
.wrapper:hover {
    background: var(--grey-5);
}
.wrapperChecked {
    background: var(--green-80);
}
.wrapperChecked:hover {
    background: var(--green-100);
}

.checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    height: 16px;
    width: 16px;
    background-color: initial;
    border-radius: 24px;
    border: 1px solid var(--black-100);
}

.checkbox input:checked ~ .checkmark {
    background-color: var(--black-100);
}

.checkmark:after {
    content: "";
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    transform: rotate(45deg);
    width: 3px;
    height: 6px;
    border: solid var(--white-100);
    border-width: 0 2px 2px 0;
    margin: 2px 0 0 4.5px;
}

.left {
    display: flex;
    gap: 10px;
}
