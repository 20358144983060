.wrapper,
.wrapperWithoutBtn {
    display: flex;
    gap: 8px;
    padding-left: 12px;
    position: relative;
    justify-content: center;
    align-items: center;
}
.wrapperWithoutBtn {
    min-width: 266px;
    height: 36px;
    justify-content: flex-start;
    position: relative;
}

.wrapper input,
.wrapperWithoutBtn input {
    border: none;
    flex-grow: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    z-index: 1;
    max-height: 14px;
    min-height: 20px;
    padding-bottom: 2px;
}
.wrapperWithoutBtn input {
    max-width: 128px;
    margin: 0 0 0 12px;
    padding-bottom: 1px;
}

.wrapper input:hover,
.wrapper input:focus-visible,
.wrapperWithoutBtn input:hover,
.wrapperWithoutBtn input:focus-visible {
    outline: none;
}
.wrapper input::placeholder,
.wrapperWithoutBtn input::placeholder {
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
}

.label {
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    opacity: 0.3;
}

.border,
.borderWithoutBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid var(--grey-40);
    border-radius: 24px;
    height: 36px;
}
.border {
    border-right: none;
}

.clearBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 4px;
    height: 36px;
    z-index: 1;
}
.wrapperWithoutBtn .clearBtn {
    position: absolute;
    right: 16px;
}

.button {
    width: 64px;
    height: 36px;
}
/************************************/
.withSuggests {
    max-width: 376px;
    overflow: hidden;
    flex-grow: 1;
    margin-right: 20px;
}

.suggests {
    background: var(--white-100);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    padding: 8px 0;
    margin-top: 4px;
    position: absolute;
    width: 376px;
    z-index: 10;
}

.suggest {
    padding: 12px;
    font-weight: 600;
    transition: background-color .2s ease-in-out;
    cursor: pointer;
}
.suggest:hover,
.suggest:focus-within,
.suggest:focus {
    background-color: var(--grey-5);
}
