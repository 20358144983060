.wrapper,
.wrapperWide {
    height: 100%;
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: var(--black-100);
    text-align: center;
}
.wrapperWide {
    width: auto;
    max-width: 392px;
    gap: 16px;
}

.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 140% !important;
}

.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: var(--black-100);
}
.wrapperWide .title {
    margin-top: -8px;
}

.wrapperWide .text {
    color: var(--grey-40);
}

.button {
    width: 100%;
    margin-top: 24px;
}
