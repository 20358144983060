.wrapper {
    position: relative;
}

.main {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 2px;
    cursor: pointer;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.top,
.bottom {
    color: var(--black-100);
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
}
.bottom {
    font-size: 11px;
}

.arrow,
.arrow svg,
.rotatedArrow,
.rotatedArrow svg {
    width: 20px;
    height: 20px;
}
.arrow {
    transform: rotate(-180deg);
    transition: transform .1s ease-in-out;
}
.rotatedArrow {
    transform: rotate(-90deg);
    transition: transform .1s ease-in-out;
}

.menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: calc(36px + 5px);
    z-index: 10;
    min-width: 160px;
    padding: 8px 0;
    border-radius: 12px;
    background: var(--white-100);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.16);
}
.menuHidden {
    display: none;
}
.menuItem {
    padding: 12px;
    background: var(--white-100);
    cursor: pointer;
    transition: background-color .2s ease-in-out;
    display: block;
    text-align: initial;
    color: var(--black-100);
}
.menuItem:hover {
    background-color: var(--grey-5);
}
