.wrapper {
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.03);
    padding: 8px;
}

.texts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px 10px 10px;
    margin-bottom: 12px;
}

.title {
    font-size: 22px;
    font-weight: 600;
    line-height: 120%;
}

.subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
}

.blurBlock {
    position: relative;
    overflow: hidden;
    margin: 0 -10px -10px;
    border-end-end-radius: 16px;
    border-end-start-radius: 16px;
}

.pictureBlured {
    width: 100%;
    height: 100%;
    background: rgba(229, 229, 229, 0.60);
    filter: blur(12.5px);
}

.actionBlock {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 120px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}
.actionBlockText {
    max-width: 240px;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
}
.actionBlockButton {
    width: fit-content;
}

.info {
    min-height: 313px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
