.wrapper {
    margin: 32px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.link {
    font-weight: 600;
    line-height: 100%;
    transition: opacity .2s ease-in-out;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    font-size: 14px;
    padding: 10px 20px 12px;
    text-align: center;
    cursor: pointer;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.link:hover {
    opacity: 0.7;
}
