.title {
    color: var(--black-100);
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.32px;
    white-space: pre-wrap;
    margin-bottom: 8px;
}

.description {
    color: var(--black-100);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 32px;
}

.controls {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.secondary {
    border-radius: 24px;
    background: var(--white-100);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    padding: 15px 24px 17px 24px;
}
