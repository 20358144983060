.list {
    position: relative;
    width: 100%;
    border-spacing: 0;
}

.list thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background-color: var(--white-100);
    white-space: nowrap;
    font-weight: 600;
}

.list th,
.list td {
    padding: 16px 10px 18px;
    text-align: start;
    vertical-align: top;
    line-height: 130%;
}

.list th:last-child {
    text-align: center;
}

.list tbody tr {
    transition: background-color .2s ease-in-out;
}
.list tbody tr:hover {
    background: rgba(0, 0, 0, 0.02);
}

.clickable {
    cursor: pointer;
}
