.checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    height: 16px;
    width: 16px;
    background-color: initial;
    border-radius: 4px;
    border: 1px solid var(--black-100);
}

.checkbox input:checked ~ .checkmark {
    background-color: var(--black-100);
}

.checkmark:after {
    content: "";
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    width: 4px;
    height: 8px;
    border: solid var(--white-100);
    border-width: 0 2px 2px 0;
    margin: 1px 0 0 4px;
    transform: rotate(45deg);
}
