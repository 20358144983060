.left,
.right {
    width: 50%;
}

.buttons {
    padding: 32px 0 40px;
    display: flex;
    gap: 20px;
    margin-top: 24px;
}
.buttons > button {
    width: 50%;
}

.pictureSign {
    margin: 8px 0 20px;
    font-weight: 600;
}
.pictureSign span {
    font-weight: 400;
}

.blockTitle {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: -12px;
}
.info div {
    line-height: 130%;
}
.infoCard {
    margin: 12px 0;
}
.infoCardTitle {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
}
.infoCardTags {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}

.onPicture {
    position: absolute;
    bottom: 12px;
    right: 12px;
}

.error {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    margin: 20px 0 0;
    opacity: 1;
    padding: 12px;
    background-color: var(--white-100);
    border: 1px solid var(--orange-100);
    border-radius: 16px;
}
.errorHidden {
    opacity: 0;
}
.errorText {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--grey-40);
}

.action {
    transition: background-color .2s ease-in-out;
    cursor: pointer;
    border-radius: 20px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.action svg {
    width: 22px;
    height: 22px;
}
.action:hover {
    background-color: var(--grey-5);
}

.loader {
    margin: -3px auto;
    position: relative;
    border-top: 2.5px solid rgba(255, 255, 255, 0.2);
    border-right: 2.5px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2.5px solid rgba(255, 255, 255, 0.2);
    border-left: 2.5px solid #ffffff;
    transform: translateZ(0);
    animation: loading 1.1s infinite linear;
    cursor: not-allowed;
}
.loader:hover {
    background-color: transparent;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
