.tags {
    width: 132px;
}

.tagsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.short {
    width: 116px;
}

.actionsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.actions {
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
}
.action {
    transition: background-color .2s ease-in-out;
    cursor: pointer;
    border-radius: 20px;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.action svg {
    width: 16px;
    height: 16px;
}
.action:hover {
    background-color: var(--grey-5);
}

.clickable {
    cursor: pointer;
}
