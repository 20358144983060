.left,
.right {
    width: 50%;
}
.right {
    border: 1px solid var(--grey-5);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 40px 12px;
    height: 380px;
}
.rightTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    margin-bottom: 20px;
}

.buttons {
    width: fit-content;
    padding: 32px 0 40px;
}

.pictureSign {
    margin: 8px 0 20px;
    font-weight: 600;
    line-height: 130%;
}
.pictureSign span {
    font-weight: 400;
    line-height: 130%;
}

.blockTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 8px;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 20px;
}
.info div {
    line-height: 130%;
}
.infoCard {
    padding: 12px 0;
}
.infoCardTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 4px;
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
}

.error {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 280px;
    margin: 20px 0 0;
    opacity: 1;
}
.errorHidden {
    opacity: 0;
}
.errorText {
    font-weight: 400;
    font-size: 11px;
    line-height: 130%;
    color: var(--grey-40);
}
.footer {
    flex-grow: 1;
}
.link {
    font-weight: 600;
    line-height: 100%;
    transition: opacity .2s ease-in-out;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    font-size: 14px;
    padding: 10px 20px 12px;
    text-align: center;
    cursor: pointer;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.link:hover {
    opacity: 0.7;
}
