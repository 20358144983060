.wrapper {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--grey-5);
    background: var(--white-100);
    margin-bottom: 43px;
    display: flex;
    gap: 24px;
    position: relative;
}

.picture {
    position: relative;
}

.picture > div {
    min-height: 332px;
    height: 332px;
    width: 332px;
}

.pictureControl {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 18px;
    right: 18px;
    border-radius: 12px;
    background: var(--white-100);
}
.pictureControl svg {
    width: 21px;
    height: 20px;
}

.texts {
    flex-grow: 1;
}

.top {
    max-width: 455px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 24px;
}

.title,
.subtitle,
.date {
    color: var(--black-100);
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.24px;
}
.subtitle {
    font-weight: 400;
}
.date {
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
}

.rightCorner {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 12px;
    position: absolute;
    top: 20px;
    right: 16px;
}

.blocks {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
}

.suggestions {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.suggestions .row {
    display: flex;
    align-items: center;
}

.suggestions .title,
.suggestions .text {
    color: var(--black-100);
    font-size: 16px;
    font-weight: 600;
    line-height: 130%;
}
.suggestions .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
}

.suggestions .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 6px;
    border: 1px solid var(--black-100);
}

/* One block */
.block {
    border-radius: 10px;
    background: #F7F7F7;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.block .icon {
    width: 32px;
    height: 32px;
    margin-bottom: 24px;
}

.block .title,
.block .sign {
    color: var(--black-100);
    font-size: 24px;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 4px;
}
.block .sign {
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 0;
    max-width: 134px;
}
/* End for One block */
