.notFoundWrapper {
    height: 100%;
    width: 360px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: var(--grey-40);
    text-align: center;
}

.notFoundText {
    font-weight: 400;
    font-size: 14px;
    line-height: 140% !important;
}

.notFoundTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    color: var(--black-100);
}

.search {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.searchText {
    color: var(--grey-40);
}
.searchCount {
    font-weight: 600;
    margin-right: 12px;
}
.searchButton {
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
}
