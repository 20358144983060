.tagsWrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.clickable {
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: space-between;
}
.clickable .icon {
    padding-right: 12px;
}
.clickable .icon svg {
    width: 20px;
    height: 20px;
    stroke-opacity: 0.4;
}

.readMore {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    z-index: 100;
}

body .tagTooltip {
    --rt-opacity: 1;
    border-radius: 12px;
    background-color: var(--white-100);
    color: var(--black-100);
    max-width: 300px;
    z-index: 10000000;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
}

.conflictTooltipContent {
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 2px 0;
    margin: 0 -4px;
}
